.image-container {
  width: 23%; 
}

.image-container:hover {
  margin: -1px;
}

@media (max-width: 800px) {
.image-container {
  width: 100%; 
}}